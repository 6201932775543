@keyframes at1 {
  from{width: 0;}
  to{width: 60px;}
}
@keyframes at2 {
  from{width: 0;}
  to{width: 76px;}
}
@keyframes at3 {
  from{width: 0;}
  to{width: 68px;}
}
@keyframes at4 {
  from{width: 0;}
  to{width: 53px;}
}
@keyframes at5 {
  from{width: 0;}
  to{width: 44px;}
}
@keyframes at6 {
  from{width: 0;}
  to{width: 8.25rem;}
}
@keyframes at7 {
  from{width: 0;}
  to{width: 10.25rem;}
}
@keyframes dt1 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes dt2 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes proj {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes dt3 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes dt4 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes dt5 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes dt6 {
  from{width: 0;}
  to{width: 50rem;}
}
@keyframes stack {
  from{width: 0}
  to{width: 17rem}
}
@keyframes fade {
  0%, 100% {opacity: 0}
  50% {opacity: 1;}
}
@keyframes fadeIn {
  from{opacity:0;}
  to{opacity:1;}
}
@keyframes fadeOut {
  from{opacity:1;}
  to{opacity:0;}
}
@keyframes fadeAndSlide {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
@keyframes inc {
  from{height: 0;}
  to{height: 100%;}
}
@keyframes inc-h {
  from{width: 0;}
  to{width: 80%;}
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50% {
    opacity: 0.9;
    transform: scale(1.1);
  }
  80% {
    opacity: 1;
    transform: scale(0.89);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0) rotate(270deg);
  }
  40% {
    transform: translateY(-5px) rotate(270deg);
  }
  60% {
    transform: translateY(-2px) rotate(270deg);
  }
}
@keyframes scaleIn {
  from {
    transform: scale(.5);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes scaleOut {
  0% {
    transform: translate(-50%, -50%) scale(1,1);
  }
  33% { opacity: .1;}
  100% {
    transform: translate(-50%, -50%) scale(4, 4);
    opacity: 0;
  }
}
