:root {
  --main-bg-color: #202020;
  --border-color: #474747;
  --text-color: #f0f0f0;
  --link-color: #bcbcbc;
}

@font-face {
  font-family: 'JetBrainsMono';
  font-style: normal;
  src: local('JetBrainsMono'), url('./fonts/JetBrainsMono-VariableFont_wght.ttf') format('truetype');
  font-display: swap;
  font-weight: 100;
}

html {
  height: 100%;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  height: 100%;
  background-color: var(--main-bg-color);
  font-family: JetBrainsMono;
  font-weight: 100;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#me {
  height: 100%;
}

h1, h5, p {
  color: var(--text-color);
  z-index: 2;
}

h1 {
  margin: 1rem 2rem;
}

a {
  font-size: .8rem;
  text-decoration: none;
  padding: 3px;
  z-index: 2;
  color: var(--link-color)!important;
  text-align: left;
}

a:focus {
  outline: none;
}

a:visited {
  color: var(--border-color);
}

span {
  padding: 3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

section {
  width: 100%;
  height: 100%;
}

ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
  margin: 7px 0;
}

footer {
  bottom: 0;
  margin-top: 1rem;
}
