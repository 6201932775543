@media screen and (min-width: 361px) and (max-width: 412px) {
  html {
    height: unset;
  }
  section {
    padding: 1rem;
    width: calc(100% - 2rem);
  }
  footer {
    left: 25%;
  }
  .row {
    flex-direction: column;
  }
  .cv .menu {margin-right: 1rem;}
  .container {
    margin-bottom: 2rem;
  }
  .nav {
    align-items: flex-end;
    justify-content: center;
  }
  .desc {padding: 0 .5rem;}
  .t6 {
    width: 9rem!important;
  }
  .d1, .d2, .d3, .d4, .d5, .d6 {
    max-width: 19rem;
  }
  .projects {
    max-width: 18rem;
  }
  #techs::after {
    left: unset;
  }
  .tech {
    font-size: inherit;
  }
  .stack-img {
    width: 50px;
  }
  .tech-chev, #techs::after {
    width: 16rem;
  }
  
  .css {
    width: 50px;
  }
  
  .native {
    width: 18px;
    border: 1px solid #fff;
    border-radius: 3px;
    padding: 14px 5px;
  }
  
  .next {
    padding: 1px 5px;
  }
  
  .jasmine {
    width: 60px;
  }
  
  .github {
    max-width: 40px;
  }

  .linkedin {
    max-width: 45px;
  }
}

@media screen and (max-width: 360px) {
  section {
    padding: .5rem;
    width: calc(100% - 1rem);
  }
  .desc {padding: 0 .5rem;}
  .cv .menu {margin-right: 1rem;}
  .cv-image {
    flex-direction: column;
  }
  .d1, .d2, .d3, .d4, .d5, .d6, .projects {
    max-width: 17rem;
  }
}
