.wrapper {
  position: relative;
  height: 100%;
  background: linear-gradient(225deg, var(--main-bg-color) 0%, #292929 65%, #404040 100%);
}

.intro {
  width: 100%;
  height: 100%;
  background-color: var(--main-bg-color);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  animation: fadeOut .3s 1.1s linear forwards;
}

.logo {
  background: url('./images/logo.png') no-repeat center center;
  background-size: cover;
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(.5);
  animation: scaleIn .6s 0s linear forwards, scaleOut .4s steps(30,end) .8s 1 normal both;
}

.grad {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--main-bg-color) 0%, #292929 65%, #1f1f1f 100%);
  border-radius: 0 100% 30% 0;
}

.grad-in {
  background-color: var(--main-bg-color);
  position: absolute;
  width: 99%;
  height: 82%;
  left: 0;
  bottom: 0;
  border-radius: 25% 100% 0 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  opacity: 0;
  z-index: 2;
  animation: fadeIn .5s .3s linear forwards;
}

.about {
  display: flex;
  text-align: center;
  height: 100%;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  flex-direction: column;
}

.container {
  height: 100%;
  align-items: center;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.nav {
  height: 4rem;
  align-items: center;
  justify-content: flex-end;
  opacity: 0;
  animation: fadeIn .3s .2s linear forwards;
}

.menu {
  font-family: JetbrainsMono;
  font-weight: bold;
  font-size: .8rem;
  transition: all .2s;
  background: none;
  border: none;
  color: var(--link-color);
  cursor: pointer;
}

.menu:focus {
  border: none;
  outline: none;
}

.menu-arrow {
  transition: all .2s;
  padding-right: 0;
}

.menu:hover {
  color: var(--text-color)!important;
  transition: all .2s;
  transform: scale(1.2);
}

.menu:hover .menu-arrow {
  transition: all .2s;
  margin-right: 5px;
  color: var(--text-color);
}
.cv {
  align-items: flex-start;
}

.image {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  margin-bottom: 2rem;
  background-image: url('./me.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  animation: fadeAndSlide .45s linear forwards;
}

.cover {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #353535;
  background: linear-gradient(135deg, #353535 0%, #666 80%);
  display: block;
  transition: all .2s ease-in;
  background-size: cover;
  opacity: 1;
}

.av-head {
  width: 60px;
  height: 60px;
  background: linear-gradient(45deg, #ddd, #666);
  transform: translate(85%, 40%);
  border-radius: 50%;
}

.av-body {
  position: absolute;
  width: 100px;
  height: 80px;
  bottom: 1px;
  left: 20%;
  border-radius: 50% 50% 35% 35%;
  background: linear-gradient(to bottom, #ddd 0%, #666 80%, transparent 100%);
}

.image:hover .cover {
  opacity: 0;
  background-color: #353535;
  background-image: none;
  cursor: default;
  transition: all .3s ease-out;
}

.tech {
  text-align: left;
  font-size: .875rem;
}

.tech .desc {
  padding-left: 0;
}

.stack {
  display: flex;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;
  margin-left: 1rem;
}

.stack li {
  opacity: 0;
  animation: bounceIn .45s .3s linear forwards;
}

.stack li:nth-child(2) { animation-delay: .35s; }
.stack li:nth-child(3) { animation-delay: .4s; }
.stack li:nth-child(4) { animation-delay: .45s; }
.stack li:nth-child(6) { animation-delay: .5s; }
.stack li:nth-child(7) { animation-delay: .55s; }

.stack-img {
  width: 30px;
  transition: all .2s;
}

.stack-img:hover {
  color: var(--link-color);
  border-color: var(--link-color);
  transform: scale(1.2);
}

#techs {
  position: relative;
}

#techs::after {
  position: absolute;
  content: '';
  height: 60px;
  background: linear-gradient(to top,  var(--main-bg-color) 50%, transparent 100%);
  bottom: 0;
  left: 0;
  width: 13rem;
  opacity: 1;
}

.closed {
  height: 110px;
  transition: all .4s ease-in-out;
}

.open {
  height: 100%;
  transition: all .3s ease-in-out;
  padding-bottom: 25px;
}

.hide {
  display: none;
}

.unset {
  height: unset!important;
}

#techs.open::after{
  opacity: 0;
  display: none;
}

.tech-chev {
  cursor: pointer;
  position: absolute;
  bottom: -11px;
  width: 15rem;
  color: #fff;
  background: transparent;
  border: none;
  margin: 6px 0;
  opacity: 0;
  z-index: 3;
  animation: fadeIn .3s .2s linear forwards;
}

.tech-chev:focus {
  border: none;
  outline: none;
}

.tech-chev span {
  cursor: pointer;
  display: inline-block;
  transform: rotate(90deg);
  animation: bounce 2s 1s;
  transition: all .2s;
}

.stack.closed .tech-chev span {
  transform: rotate(270deg);
  transition: all .2s;
}

.stack ul li {
  margin: 0 5px;
}

.stack ul li svg {
  font-size: 1.4rem;
  color: var(--text-color);
}

.js {
  width: 40px;
}

.css {
  width: 50px;
}

.native {
  width: 18px;
  border: 1px solid #fff;
  border-radius: 3px;
  padding: 11px 3px;
}

.next {
  padding: 0 3px;
}

.electron {
  width: 40px;
}

.jasmine {
  width: 60px;
}

.backbone {
  width: 70px;
}

.github:hover {
  background-color: var(--link-color);
}

.flutter {
  width: 20px;
}

.w-bg {
  background-color: #fff;
}

.cv-image {
  display: flex;
  align-items: center;
  opacity: 0;
  animation: fadeIn .3s .2s linear forwards;
}

.job {
  margin: 1rem 2rem;
}

.title {
  font-size: .875rem;
  border-bottom: 1px solid var(--border-color);
  padding: 10px 10px 10px 0;
  margin: .5rem;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-weight: 100;
  opacity: 0;
}

.desc {
  padding: 0 1rem;
  margin: .5rem;
  font-size: .75rem;
  font-weight: 100;
  white-space: pre-line;
  overflow: hidden;
  opacity: 0;
  text-align: left;
}

.projects {
  text-align: left;
  color: var(--text-color);
  font-size: .75rem;
  margin-left: 1.7rem;
  opacity: 0;
  animation: fadeIn .3s .3s linear forwards, proj .3s steps(30,end) .3s 1 normal both;
}

.projects ul {
  margin-left: 1.5rem;
  list-style-type: circle;
}

.projects li {
  width: 100%;
}

.t1 {
  animation: fadeIn .3s 0s linear forwards, at1 .3s steps(30,end) .1s 1 normal both;
}
.t2 {
  animation: fadeIn .3s .2s linear forwards, at2 .3s steps(30,end) .2s 1 normal both;
}
.t3 {
  animation: fadeIn .3s .3s linear forwards, at3 .3s steps(30,end) .3s 1 normal both;
}
.t4 {
  animation: fadeIn .3s .4s linear forwards, at4 .3s steps(30,end) .4s 1 normal both;
}
.t5 {
  animation: fadeIn .3s .5s linear forwards, at5 .3s steps(30,end) .5s 1 normal both;
}
.t6 {
  animation: fadeIn .3s 0s linear forwards, at6 .3s steps(30,end) .1s 1 normal both;
}
.t7 {
  animation: fadeIn .3s 0s linear forwards, at7 .3s steps(30,end) .1s 1 normal both;
}

.d1 {
  animation: fadeIn .3s .2s linear forwards, dt1 .3s steps(30,end) .2s 1 normal both;
}
.d2 {
  animation: fadeIn .3s .3s linear forwards, dt2 .3s steps(30,end) .3s 1 normal both;
}
.d3 {
  animation: fadeIn .3s .4s linear forwards, dt3 .3s steps(30,end) .4s 1 normal both;
}
.d4 {
  animation: fadeIn .3s .5s linear forwards, dt4 .3s steps(30,end) .5s 1 normal both;
}
.d5 {
  animation: fadeIn .3s .6s linear forwards, dt5 .3s steps(30,end) .6s 1 normal both;
}
.d6 {
  animation: fadeIn .3s .7s linear forwards, dt6 .3s steps(30,end) .7s 1 normal both;
}

p a {
  color: var(--link-color)!important;
}

footer p {
  font-size: .6rem;
  font-weight: 100;
  opacity: 0;
  animation: fadeIn .4s .5s linear forwards;
  margin-top: 1rem;
}

footer .copy {
  font-size: .9rem;
  vertical-align: middle;
}
